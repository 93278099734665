import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Mortgages = () => (
  <Layout>
    <SEO title="mortgages" />
    <h1>Mortgages</h1>
    <Image alt="house" filename="house.jpg"/>
    
  </Layout>
)

export default Mortgages